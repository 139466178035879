.App {
  text-align: center;
  background-color: #ffffff;
}

header {
  background: #001f3f;
  color: white;
}

.hero {
  background: #007bff;
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing {
  background: #f4f4f4;
  color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  background: #001f3f;
  color: white;
  padding: 20px 0;
  text-align: center;
}
